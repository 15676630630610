
import { Component, Mixins, Watch } from 'vue-property-decorator';
import {
  BlockTitle,
  Checkbox,
  Panels,
  PanelsItem,
  Table,
  Select,
  CommentDialog,
} from '@/components';
import { PanelItem } from '@/types';
import { clients_store } from '@/store';
import { OrderTypes } from '@/enums';
import { ErrorHandlereMixin } from '@/mixins';
import JumpsForm from '../../components/client-card/JumpsForm.vue';
import { comments_table_header } from '../../constans';
import ClientCardHeaderActions from '../../components/client-card/ClientCardHeaderActions.vue';
import EditClientDialog from '../../components/dialogs/EditClientDialog.vue';
import { Clients } from '../../types';
import ClientTicketsTabs from '../../components/client-card/ClientTicketsTabs.vue';

@Component({
  components: {
    BlockTitle,
    Checkbox,
    Panels,
    PanelsItem,
    JumpsForm,
    Table,
    ClientTicketsTabs,
    CommentDialog,
    Select,
    ClientCardHeaderActions,
    EditClientDialog,
  },
})
export default class ClientSharedCard extends Mixins(ErrorHandlereMixin) {
  @Watch('client')
  @Watch('tickets_type')
  async get_tickets() {
    if (this.client?.id) {
      try {
        this.is_tickets_loading = true;
        await clients_store.actions.get_client_tickets({
          id: this.client.id,
          type: this.tickets_type,
        });

        this.is_tickets_loading = false;
      } catch (error) {
        this.handleServerError(error);
      }
    }
  }

  is_edit_dialog = false;
  is_add_comment_dialog = false;
  tickets_type = OrderTypes.ONLINE;

  panels_model = [
    1, 3,
  ];

  panels_content: PanelItem[] = [
    {
      title: 'Прыжки',
      text: 'jumps',
    },
  ];

  comments_table_header = comments_table_header;

  is_tickets_loading = false;

  get comments() {
    return this.client?.comments ?? [];
  }

  get tickets() {
    return clients_store.state.selected_client_tickets;
  }

  get selected_client() {
    return clients_store.state.selected_client;
  }

  get client() {
    return clients_store.state.selected_client;
  }

  close() {
    clients_store.actions.close_client();
  }

  update_tickets_type(type: OrderTypes) {
    this.tickets_type = type;
  }

  edit_dialog() {
    this.is_edit_dialog = true;
  }

  update_jumps_by_client(params: {type: 'bungy69_count' | 'bungy207_count' | 'sochi_swing_count', value: number}) {
    if (this.client) {
      this.client[params.type] = +params.value;
      this.edit_client(this.client);
    }
  }

  on_client_status_changed(params?: {status_key: 'is_pro' | 'is_club_member', status_value: boolean}) {
    if (this.client) {
      if (params) {
        this.client[params.status_key] = params.status_value;
      }

      this.edit_client(this.client);
    }
  }

  async add_comment(comment: string) {
    try {
      if (this.client?.id) {
        await clients_store.actions.create_comment({
          id: this.client?.id,
          comment,
        });
        this.is_add_comment_dialog = false;
      }
    } catch (error) {
      this.handleServerError(error);
    }
  }

  async edit_client(client: Clients.Client) {
    try {
      await clients_store.actions.edit_client(client);
      this.is_edit_dialog = false;
    } catch (error) {
      this.handleServerError(error);
    }
  }
}
